<style scoped>
.svgContent {
  width: 100%;
  text-align: center
}
</style>

<template>
  <div id="mapTest">
    <div v-html="svgHtml" id="svgLine_Heat" class="svgContent"></div>
  </div>
</template>

<script>
import h337 from 'heatmap.js'
import svgPanZoom from 'svg-pan-zoom'

import { getCitys } from '@/api/product/asset'
import { getCitySvg } from '@/api/rim/asset'

export default {
  data () {
    return {
      svgHtml: '',
      publisherId: this.$store.getters.token.userInfo.publisherId,

      mapCenterPoint: [
        { publisherId: 6, center: '5429,10707', city: '宁波' },
        { publisherId: 13, center: '5307,267', city: '青岛' },
        { publisherId: 25, center: '11339,11366', city: '佛山' }
      ],

      currentMapObj: null,
      pathNodeList: [],
      stationCoordinateArray: [], // 站点坐标体系
      flag: 0,
      heapMapIns: null
    }
  },
  mounted () {
    this.initMap()
    // 设置中间区域地图高度
    this.$nextTick(() => {
      document.getElementById('svgLine_Heat').style.height = document.body.clientHeight - 5 + 'px'
    })
  },
  methods: {
    initMap () {
      this.stationCoordinateArray = []
      this.$emit('map-finished', false)

      getCitys({ publisherId: this.publisherId }).then(citys => {
        const data = {
          cityCode: citys[0].cityCode,
          categoryId: null,
          publisherId: this.publisherId
        }

        getCitySvg(data).then(res => {
          this.svgHtml = res
          // 设置SVG地图的样式和缩放
          this.$nextTick(() => {
            // 设置svg呈现在画板上的尺寸
            document.getElementById('drawing').setAttribute('width', '100%')
            document.getElementById('drawing').setAttribute('height', '100%')

            this.currentMapObj = svgPanZoom('#drawing', {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              minZoom: 1,
              maxZoom: 5,
              center: true
            })

            this.pathNodeList = document.getElementById('svgLine_Heat').getElementsByTagName('path')

            this.$emit('map-finished', true)
          })
        })
      })
    },
    autoPointCenter () {
      let pathElement
      let pathStationId
      let centerPointStr

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pathElement = this.pathNodeList[i]
        if (pathElement.hasAttribute('data-stationid') && (pathElement.getAttribute('name') === 'p_station' || pathElement.getAttribute('name') === 'p_r_station')) {
          // 获取当前元素所对应的站点ID
          pathStationId = pathElement.getAttribute('data-stationid')
          // 设置地图中心点
          if (pathStationId === this.mapCenterPoint.find(x => x.publisherId === this.publisherId).center) {
            centerPointStr = pathElement.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
            break
          }
        }
      }

      const centerPoint = {
        id: pathElement.getAttribute('data-stationid'),
        x: parseInt(centerPointStr[0]),
        y: parseInt(centerPointStr[1])
      }

      // 计算出实际跳转坐标
      const svgSizes = this.currentMapObj.getSizes()
      if (centerPoint) { // 处理关闭窗口后的空值
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapAnimation (targetPoint) {
      const _self = this
      const currentPan = this.currentMapObj.getPan()

      var animationTime = 300 // ms
      var animationStepTime = 30 // one frame per 30 ms
      var animationSteps = animationTime / animationStepTime
      var animationStep = 0
      var intervalID = null
      var stepX = (currentPan.x - targetPoint.x) / animationSteps
      var stepY = (currentPan.y - targetPoint.y) / animationSteps

      intervalID = setInterval(function () {
        if (animationStep++ < animationSteps) {
          _self.currentMapObj.pan({ x: currentPan.x - stepX * animationStep, y: currentPan.y - stepY * animationStep })
        } else {
          // 取消无限循环, 获取页面站点坐标系
          clearInterval(intervalID)

          setTimeout(() => {
            _self.getStationCoordinate()
          }, 1000)
        }
      }, animationStepTime)
    },
    getStationCoordinate () {
      let pEl
      const coordinateArray = []

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-stationid') && (pEl.getAttribute('name') === 'p_station' || pEl.getAttribute('name') === 'p_r_station')) {
          coordinateArray.push({
            stationId: pEl.getAttribute('data-stationid'),
            x: parseInt(pEl.getBoundingClientRect().x) - 115,
            y: parseInt(pEl.getBoundingClientRect().y) + 5
          })
        }
      }

      this.stationCoordinateArray = coordinateArray
    },
    initHeatMapOrigin (heatData) {
      if (this.stationCoordinateArray.length > 0) {
        let stationIds = []
        let stationData = []
        let heatValue = 0
        const pointsArr = []

        this.stationCoordinateArray.forEach(element => {
          stationIds = element.stationId.split(',')
          stationData = heatData.data.filter(x => stationIds.includes(x.stationId))

          heatValue = 0
          stationData.forEach(item => {
            heatValue += item.count
          })

          if (heatValue > 0) {
            pointsArr.push({
              x: element.x,
              y: element.y,
              value: heatValue
            })
          }
        })

        if (!this.heapMapIns) {
          this.heapMapIns = h337.create({
            container: document.getElementById('mapTest')
          })
        }

        this.heapMapIns.setData({
          max: heatData.max,
          data: pointsArr
        })
      } else if (this.flag < 10) {
        this.flag++
        setTimeout(() => {
          this.initHeatMapOrigin(heatData)
        }, 1000)
      }
    },
    clearMap () {
      this.currentMapObj = null
      this.heapMapIns = null
    },
    changeMapSize (mapSize) {
      // 重新加载地图大小
      this.currentMapObj.zoom(mapSize)
      this.autoPointCenter()
    }
  }
}
</script>
