<template>
  <div class="heat-content">

    <div v-show="!showSearchCondition" class="heat-search">
      <Button @click="showSearchCondition = true" icon="ios-search" type="primary">查询</Button>
    </div>
    <!-- 显示查询条件 -->
    <Drawer :closable="false" v-model="showSearchCondition" :mask="false" :styles="drawerStyles">
      <div style="background-color: white;">
        <h3 class="m-b-5">数据统计时间</h3>

        <div>
          <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleSearchData">
            <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
          </Select>
          <span class="m-l-5 m-r-5">至</span>
          <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleSearchData">
            <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
          </Select>
        </div>

        <h3 class="m-b-5 m-t-20">统计数据类型</h3>
        <RadioGroup v-model="queryModel.heatType" @on-change="handleSearchData">
            <Radio label="0">投放量</Radio>
            <Radio label="1" >去化率</Radio>
            <Radio label="2" >预售率</Radio>
            <Radio label="3" >品牌指数</Radio>
            <Radio label="4" >签约额</Radio>
            <Radio label="5" >执行额</Radio>
        </RadioGroup>
        <h3 class="m-b-5 m-t-20">地图类型</h3>
        <RadioGroup v-model="mapType">
            <Radio label="0">地铁线路图</Radio>
            <Radio label="1">第三方地图</Radio>
            <Radio label="2">站内地图</Radio>
        </RadioGroup>
        <template v-if="mapType==='0'">
          <h3 class="m-b-5 m-t-20">地图放大</h3>
          <Slider :min="1" :max="5" show-stops @on-change="handleChangeMapSize"></Slider>
        </template>

        <p class="m-t-20">
          <Button type="primary" @click="showSearchCondition = false">收起查询条件</Button>
        </p>
        <Spin v-if="spinShow" fix>地图加载中</Spin>
      </div>
    </Drawer>

    <component :is="componentName" :queryModel="queryModel" ref="heatMapComponent"
      @map-finished="handleMapLoadFinished"/>

      <Spin v-if="spinShow" fix>
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据加载中...</div>
      </Spin>
  </div>
</template>

<script>
import GeoMapWhite from './components/GeoMapWhite.vue'
import SvgLineWhite from './components/SvgLineWhite.vue'
import SvgLineStation from './components/SvgLineStation.vue'

import { GetCurrentMonth, lastMonthDate, getMonth } from '@/utils/dateFormat'
import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { countBrandNumberGbStationName, listResourceRateGbStationName } from '@/api/dw/station'
import { countContractAmountHeatMap, countExecuteAmountHeatMap } from '@/api/dw/datav'

export default {
  components: {
    GeoMapWhite, SvgLineWhite, SvgLineStation
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      showSearchCondition: false,
      spinShow: false,
      componentName: '',
      mapFinished: false,
      drawerStyles: {
        'background-color': '#fff',
        color: '#000'
      },

      startDateArray: [],
      endDateArray: [],

      queryModel: {
        heatType: '0',
        endDate: '',
        startDate: ''
      },
      mapType: '0'
    }
  },
  mounted () {
    this.queryModel.startDate = getMonth(new Date(), -5) + '-01'
    this.queryModel.endDate = GetCurrentMonth().endDate

    this.initPageData()
    this.loadHeatMap()
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    loadHeatMap () {
      if (this.mapType === '0') {
        this.componentName = 'SvgLineWhite'
        this.$nextTick(() => {
          this.LoadMapData()
        })
        this.handleSearchData()
      } else if (this.mapType === '1') {
        this.componentName = 'GeoMapWhite'
        this.handleSearchData()
      } else {
        this.componentName = 'SvgLineStation'
      }
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        this.$refs.heatMapComponent.autoPointCenter()
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    handleSearchData () {
      // 如果是站内地图，那么不处理
      if (this.mapType === '2') { return }

      this.spinShow = true

      const searchModel = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate,
        publisherId: this.publisherId
      }

      if (this.queryModel.heatType === '2') { // 预售率
        searchModel.startDate = GetCurrentMonth().startDate
        const dateObj = new Date()
        searchModel.endDate = lastMonthDate(dateObj.getFullYear(), dateObj.getMonth() + 5)
        this.loadResourceRate(searchModel)
      } else if (this.queryModel.heatType === '3') { // 品牌指数
        this.loadBrandNumber(searchModel)
      } else if (this.queryModel.heatType === '4') { // 签约额
        this.loadContractAmount(searchModel)
      } else if (this.queryModel.heatType === '5') { // 执行额
        this.loadExecuteAmount(searchModel)
      } else {
        this.loadResourceRate(searchModel)
      }
    },
    loadBrandNumber (searchModel) {
      countBrandNumberGbStationName(searchModel).then(res => {
        let maxCount = 0
        const heatData = []
        res.forEach(item => {
          if (item.lng !== '') {
            if (maxCount < item.number) {
              maxCount = item.number
            }
            heatData.push({
              stationId: item.stationId.toString(),
              lng: parseFloat(item.lng),
              lat: parseFloat(item.lat),
              count: item.number
            })
          }
        })

        this.initHeatMap({
          data: maxCount > 0 ? heatData : [], // 防止无数据导致出现蓝色圆圈
          max: maxCount
        })
      })
    },
    loadContractAmount (searchModel) {
      countContractAmountHeatMap(searchModel).then(res => {
        let maxCount = 0
        const heatData = []
        res.forEach(item => {
          if (item.lng !== '') {
            if (maxCount < item.value) {
              maxCount = item.value
            }
            heatData.push({
              stationId: item.stationId.toString(),
              lng: parseFloat(item.lng),
              lat: parseFloat(item.lat),
              count: item.value
            })
          }
        })

        this.initHeatMap({
          data: maxCount > 0 ? heatData : [], // 防止无数据导致出现蓝色圆圈
          max: maxCount
        })
      })
    },
    loadExecuteAmount (searchModel) {
      countExecuteAmountHeatMap(searchModel).then(res => {
        let maxCount = 0
        const heatData = []
        res.forEach(item => {
          if (item.lng !== '') {
            if (maxCount < item.value) {
              maxCount = item.value
            }
            heatData.push({
              stationId: item.stationId.toString(),
              lng: parseFloat(item.lng),
              lat: parseFloat(item.lat),
              count: item.value
            })
          }
        })

        this.initHeatMap({
          data: maxCount > 0 ? heatData : [], // 防止无数据导致出现蓝色圆圈
          max: maxCount
        })
      })
    },
    loadResourceRate (searchModel) {
      listResourceRateGbStationName(searchModel).then(res => {
        let maxCount = 0
        const heatData = []
        res.forEach(item => {
          if (item.lng !== '') {
            if (this.queryModel.heatType === '0') {
              if (maxCount < item.number) {
                maxCount = item.number
              }
              heatData.push({
                stationId: item.stationId.toString(),
                lng: parseFloat(item.lng),
                lat: parseFloat(item.lat),
                count: item.number
              })
            } else {
              if (maxCount < item.rate) {
                maxCount = item.rate
              }
              heatData.push({
                stationId: item.stationId.toString(),
                lng: parseFloat(item.lng),
                lat: parseFloat(item.lat),
                count: item.rate
              })
            }
          }
        })

        this.initHeatMap({
          data: maxCount > 0 ? heatData : [],
          max: maxCount
        })
      })
    },
    initHeatMap (heatMapData) {
      this.$refs.heatMapComponent.initHeatMapOrigin(heatMapData)
      this.spinShow = false
    },
    handleChangeMapSize (val) {
      const _self = this

      _self.spinShow = true
      _self.$refs.heatMapComponent.changeMapSize(val)
      setTimeout(() => {
        _self.handleSearchData()
      }, 1000)
    }
  },
  watch: {
    mapType (val) {
      this.$refs.heatMapComponent.clearMap()
      this.loadHeatMap()
    }
  }
}
</script>

<style scoped>
.heat-content{
  height: 100vh;
  width: 100vw;
  background-color: white;
}
.heat-search{
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 999;
}
</style>
